import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EngagementPage = () => (
  <Layout>
    <SEO title="engagements" />
    <h1>Engagements</h1>
    <iframe title="engagement-video" width="560" height="315" src="https://www.youtube.com/embed/S1Gooeywvz0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Layout>
)

export default EngagementPage
